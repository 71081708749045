









































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class ContactForm extends Vue {
  fadeIn(el: HTMLElement) {
    el.style.display = "flex";
    (function fade() {
      let val = parseFloat(el.style.opacity);
      if (!((val += 0.1) > 1)) {
        el.style.opacity = val.toString();
        requestAnimationFrame(fade);
      }
    })();
  }

  changeSteps(step: 1 | 2 | 3 | 4) {
    document
      .getElementsByClassName("product-content")
      /* eslint-disable */
      // @ts-ignore
      .forEach((el: HTMLElement) => {
        /* eslint-enable */
        el.style.display = "none";
      });

    const productEl = document.getElementById("product-container-" + step);
    if (!productEl) return console.error("Product element does not exist");
    productEl.style.visibility = "visable";
    this.fadeIn(productEl);

    document
      .getElementsByClassName("product-button")
      /* eslint-disable */
      // @ts-ignore
      .forEach((el: HTMLElement) => {
        /* eslint-enable */
        el.classList.remove("product-button-active");
      });

    document
      .getElementById("product-button-" + step)
      ?.classList.add("product-button-active");
  }
}
