<template>
  <div class="index">
    <Nav />
    <ServicesPage />
    <Footer />
  </div>
</template>

<script>
import Nav from "@/components/nav.vue";
import Footer from "@/components/footer.vue";
import ServicesPage from "@/components/servicesPage.vue";
export default {
  name: "Services",
  components: {
    Nav,
    ServicesPage,
    Footer
  },
  metaInfo: {
    title: "SeismicCore | Services",
    meta: [
      { name: "theme-color", content: "#57b3fd" },
      { property: "og:title", content: "SeismicCore | Services" },
      {
        property: "og:description",
        content: "Development services offered by SeismicCore."
      },
      {
        name: "description",
        content: "Development services offered by SeismicCore."
      },
      { name: "twitter:title", content: "SeismicCore | Services" },
      {
        name: "twitter:description",
        content: "Development services offered by SeismicCore."
      }
    ]
  }
};
</script>
<style>
@import "../assets/css/main.css";
</style>
